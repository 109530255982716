/* eslint-disable */
// @ts-nocheck
// cSpell:disable
// This file was generated by lezer-generator. You probably shouldn't edit it.
import { LRParser } from '@lezer/lr';
import { startTag, elementContext } from './template.tokens.js';
import { xmlHighlighting } from './template.highlight.js';
export const parser = LRParser.deserialize({
    version: 14,
    states: "%WOQOROOOcOSO'#ClOqORO'#CeOOOP'#Ce'#CeO{OSO'#CoOOOP'#Ct'#CtOOOP'#Cp'#CpQQOROOO!QOSO,59RO!VOSO,59WO!bOSO'#CnOOOO'#Cn'#CnOOOO'#Cq'#CqO!jOSO,59WO!rOSO'#CkOOOP,59P,59PO!wORO,59PO#ROSO,59ZOOOP-E6n-E6nO#WOSO1G.mOOOP1G.r1G.rOOOO'#Cr'#CrOOOO-E6p-E6pOOOO,59Y,59YO#]OSO1G.rOOOO-E6o-E6oO#hOSO,59VOOOP1G.k1G.kOOOP1G.u1G.uOOOP7+$X7+$XOOOP7+$^7+$^OOOP1G.q1G.q",
    stateData: '#m~OPPOSSOTTOVTOWTO~O[WO]WO^XOaZO~OQ^OR_O~PQO^aO~O^cO~OYdO^fXafX~O^eOagO~O^hOaZO~O^jO~OQ^ORkO~PQOYlO~OYmO~OYnO^fXafX~OYoO~O',
    goto: '!uiPPPPPPPPPjPpPPPv|P!Sj!W!b!hP!oXTOQV`XQOQV`Q_QRk`XROQV`T[P]QVOQ`QTbV`Q]PRi]SYP]RfYXUOQV`',
    nodeNames: '⚠ StartTag StartCloseTag MissingCloseTag StartCloseTag StartCloseTag Document Text Text Element EndTag OpenTag If Unless TagName CloseTag SelfClosingTag TransformerDelimiter Transformer MismatchedCloseTag',
    maxTerm: 24,
    context: elementContext,
    nodeProps: [
        ['closedBy', 1, 'EndTag', 11, 'CloseTag MissingCloseTag'],
        ['openedBy', 10, 'StartTag StartCloseTag', 15, 'OpenTag'],
        ['isolate', -4, 11, 15, 16, 19, ''],
    ],
    propSources: [xmlHighlighting],
    skippedNodes: [0],
    repeatNodeCount: 3,
    tokenData: "&fRR]Oszst#wt![z![!]$[!]#Qz#Q#R$o#R#oz#o#p%S#p#qz#q#r%j#r;'Sz;'S;=`#q<%lOzR!RX^QVPO![z![!]!n!]#oz#o#p#V#p#qz#q#r!n#r;'Sz;'S;=`#q<%lOzP!sSVPO#o!n#p;'S!n;'S;=`#P<%lO!nP#SP;=`<%l!nQ#[T^QO![#V!]#q#V#r;'S#V;'S;=`#k<%lO#VQ#nP;=`<%l#VR#tP;=`<%lzR$OS[QVPO#o!n#p;'S!n;'S;=`#P<%lO!nR$cSVPaQO#o!n#p;'S!n;'S;=`#P<%lO!nR$vSVP]QO#o!n#p;'S!n;'S;=`#P<%lO!nR%ZTWP^QO![#V!]#q#V#r;'S#V;'S;=`#k<%lO#VR%oUVPO#o!n#p#q!n#q#r&R#r;'S!n;'S;=`#P<%lO!nR&YSYQVPO#o!n#p;'S!n;'S;=`#P<%lO!n",
    tokenizers: [startTag, 0, 1],
    topRules: { Document: [0, 6] },
    tokenPrec: 0,
});
